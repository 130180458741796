<template>
    <div class="cpsTagProductWrap">
        <div class="cpsTagProduct">
            <div class="img van-image">
                <img
                    v-bind:src="`${selectedProduct ? selectedProduct.pic_path : ''}`"
                    class="van-image__img">
            </div>
            <div class="content">
                <div class="denomination">面额</div>
                <div class="denominationList">
                    <a v-for="(product) in this.productList" :key="product.id"
                       @click="selectProduct(product)"
                       :class="`denominationItem ${selectedProduct.id == product.id ? 'denomination_active' : ''}`">
                        <div class="name">{{ product.origin_price / 100 }}元电费代缴</div>
                        <div class="priceWarp">
                            <span class="priceCompany">¥</span>
                            <span class="priceNum">{{ product.price / 100 }}</span>
                        </div>
                        <div class="oldPrice">¥{{ product.origin_price / 100 }}</div>
                    </a>

                    <!--                    <a href="javascript:void(0);" class="denominationItem">-->
                    <!--                        <div class="name">1000元电费代缴</div>-->
                    <!--                        <div class="priceWarp">-->
                    <!--                            <span class="priceCompany">¥</span>-->
                    <!--                            <span class="priceNum">950</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="oldPrice">¥1000</div>-->
                    <!--                    </a>-->
                    <!--                    <a href="javascript:void(0);" class="denominationItem">-->
                    <!--                        <div class="name">500元电费代缴</div>-->
                    <!--                        <div class="priceWarp">-->
                    <!--                            <span class="priceCompany">¥</span>-->
                    <!--                            <span class="priceNum">475</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="oldPrice">¥500</div>-->
                    <!--                    </a>-->
                    <!--                    <a href="javascript:void(0);" class="denominationItem">-->
                    <!--                        <div class="name">【不限购】100元电费代缴</div>-->
                    <!--                        <div class="priceWarp">-->
                    <!--                            <span class="priceCompany">¥</span>-->
                    <!--                            <span class="priceNum">95</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="oldPrice">¥100</div>-->
                    <!--                    </a>-->
                    <!--                    <a href="javascript:void(0);" class="denominationItem">-->
                    <!--                        <div class="name">【不限购】300元电费代缴</div>-->
                    <!--                        <div class="priceWarp">-->
                    <!--                            <span class="priceCompany">¥</span>-->
                    <!--                            <span class="priceNum">286</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="oldPrice">¥300</div>-->
                    <!--                    </a>-->
                    <!--                    <a href="javascript:void(0);"-->
                    <!--                       class="denominationItem denomination_active">-->
                    <!--                        <div class="name">【限1单】100元电费代缴</div>-->
                    <!--                        <div class="priceWarp">-->
                    <!--                            <span class="priceCompany">¥</span>-->
                    <!--                            <span class="priceNum">93</span>-->
                    <!--                        </div>-->
                    <!--                        <div class="oldPrice">¥100</div>-->
                    <!--                    </a>-->
                </div>
                <div class="chargeAccount">充值户号</div>
                <van-form class="form">
                    <field
                        v-model="charge_account"
                        class="inphone"
                        placeholder="请输入充值户号"

                    />
                    <div class="chargeArea">选择地区</div>
                    <field
                        v-model="area"
                        class="area"
                        placeholder="请选择充值地区"
                        readonly
                        @click="this.showAreaFn"
                    />
                </van-form>
                <!--                <form class="form van-form">-->

                <!--                    <div role="button" tabindex="0" class="area van-cell van-cell&#45;&#45;clickable van-field">-->
                <!--                        <div class="van-cell__value van-cell__value&#45;&#45;alone van-field__value">-->
                <!--                            <div class="van-field__body">-->
                <!--                                <input type="text" name="tel" readonly="readonly"-->
                <!--                                       placeholder="请选择充值地区" class="van-field__control"></div>-->
                <!--                        </div>-->
                <!--                    </div>-->
                <!--                </form>-->
            </div>
            <div class="introduce" v-html="`${selectedProduct ? selectedProduct.desc : ''}`">
<!--                <p>-->
<!--                    <span-->
<!--                        style="font-size: x-large; font-weight: bold;">注意填写正确的户号！！！<br>国家电网账号，一户一号！<br>不清楚的可以拨打国家电网95598客服电话，提供住址或者身份证号码查询！</span><br>-->
<!--                </p>-->
<!--                <p>-->
<!--                    <span style="font-weight: bold; font-size: medium;">充值说明：<br>1、居民单缴费金额不能小于欠费金额（欠费200以内）；<br>2、单户日限额五千，月上限3w ；<br>3、电费可能会以多次如50元/100元等分次到账，可叠加充值；</span>-->
<!--                </p>-->
<!--                <p><span style="font-weight: bold; font-size: medium;">4、仅限国家电网用户，广东省、海南省、广西省、贵州省、云南省、西藏南方电网地区以及天津地区无法充值；</span>-->
<!--                </p>-->
<!--                <p><span style="font-weight: bold;"><span style="font-size: medium;">5、有问题的号码，欠费金额过多的号码，会充值失败；<br>6、本商品为慢充，一般24小时内到账，高峰期延迟至48~72小时；&nbsp;&nbsp;<br>7、到账查询：充值完成后可到国网APP查询余额。&nbsp;</span>&nbsp;</span>-->
<!--                </p>-->
            </div>
            <div class="footer">
                <a class="service" @click="goToService">
                    <img
                        src="https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/37be8172dd294eb098384d384fe3ee0b.png"
                        alt="">
                    <div>客服</div>
                </a> <a class="order">
                <img
                    src="https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/920df55339a14c7e8af6cc46fe0860ac.png"
                    alt="">
                <div @click="goToOrderList">我的订单</div>
            </a>
                <div class="footerBtnWarp">
                    <van-button class="btn" @click="onShowConfirmAccountModal">立即购买</van-button>
                    <!--                    <button class="btn van-button van-button&#45;&#45;default van-button&#45;&#45;normal">-->
                    <!--                        <div class="van-button__content">-->
                    <!--                            <span class="van-button__text"> 立即购买 </span>-->
                    <!--                        </div>-->
                    <!--                    </button>-->
                </div>
            </div>
        </div>
        <overlay :show="show">
            <div class="wrapper">
                <div class="block"></div>
            </div>
        </overlay>
        <popup
            v-model="showArea"
            position="bottom"
        >
            <!--                  @change="onChange"-->
            <picker
                title="地区"
                show-toolbar
                :columns="this.locationList"
                @confirm="onConfirm"
                @cancel="onCancel"
            ></picker>
        </popup>
        <popup
            v-model="accountModal"
            class="confirmAccount"
        >
            <div class="confirmWarp">
                <div class="please">请确认充值账号</div>
                <div class="pleaseAccount">
                    <span>充值账号：</span> {{ this.charge_account }}
                </div>
                <van-button class="confirmBtn" @click="submitData">确认并充值</van-button>
                <icon class="iconClose" name="close" @click="closeAccountModal"/>
            </div>
        </popup>

        <qr :url="url"/>
    </div>
</template>
<script>
import {Form, Field, Overlay, Popup, Picker, Button as vanButton, Icon} from 'vant';
import {elecProdectList, elecPay} from "../../service/electricity";
import Qr from "@/views/components/qr"
import {getOrigin} from "@/utils/common"


export default {
    components: {
        vanForm: Form,
        Field,
        Overlay,
        Popup,
        Picker,
        vanButton,
        Icon,
        Qr
    },
    data() {
        console.log(getOrigin()+"/electricity")
        return {
            charge_account: localStorage.getItem('charge_account')||'',
            area: '',
            show: false,
            showArea: false,
            accountModal: false,
            productList: [],
            locationList: [],
            selectedProduct: null,
            url:getOrigin()+"/electricity",
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async initData() {
            let data = await elecProdectList({})
            this.productList = data.product_list
            this.locationList = data.location_list
            if (this.productList.length) {
                this.selectedProduct = this.productList[0]
            }
            // console.log('aaa', data)
        },
        selectProduct(product) {
            // console.log('pppp', product)
            this.selectedProduct = product
        },
        showAreaFn() {
            this.showArea = true
        },
        onConfirm(value) {
            // Toast(`当前值：${value}, 当前索引：${index}`);
            this.showArea = false
            this.area = value
        },
        // onChange(picker, value, index) {
        //     Toast(`当前值：${value}, 当前索引：${index}`);
        // },
        onCancel() {
            //Toast('取消');
            this.showArea = false
        },
        onShowConfirmAccountModal() {
            this.accountModal = true
        },
        closeAccountModal() {
            this.accountModal = false
        },
        submitData() {
            this.accountModal = false
            this.goPay()
        },
        goToService() {
            location.href = 'https://work.weixin.qq.com/kfid/kfc3210d9a47c95b749';
        },
        async goPay() {
            let pay_method = 1;
            let product = this.selectedProduct
            if (navigator.userAgent.match(/MicroMessenger/i)) {
                pay_method = 1
            }
            let postData = {
                pay_method: pay_method,
                product_type: product.product_type,
                location: this.area,
                product_value: product.origin_price,
                charge_account:this.charge_account
            }
            localStorage.setItem('charge_account',this.charge_account)
            let {config} = await elecPay(postData)
            if (config.pay_url) {
                window.location.href = config.pay_url
                return
            }
        },
        goToOrderList(){
            this.$router.push({path:'/order_list'})
        }
    }
}
</script>
<style scoped>
.van-image__error, .van-image__img, .van-image__loading {
    display: block;
    width: 100%;
    height: 100%
}

.cpsTagProduct {
    min-height: 100vh;
    background-color: #f8f8f8
}

.cpsTagProduct .img {
    width: 100vw;
    height: 9.519rem
}

.cpsTagProduct .title {
    width: 100vw;
    height: 15rem;
    background-color: #fff
}

.cpsTagProduct .content {
    position: relative;
    top: -.36rem;
    padding: .6rem .4rem;
    margin: 0 .6rem;
    background-color: #fff;
    border-radius: .36rem;
    border: 1px solid #f1f1f1;
    -webkit-box-shadow: 0 5px 8px 0 rgba(96, 83, 75, .05);
    box-shadow: 0 5px 8px 0 rgba(96, 83, 75, .05)
}

.cpsTagProduct .content .productTitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: .6rem
}

.cpsTagProduct .content .productTitle > img {
    width: .879rem;
    height: .879rem;
    border-radius: 50%
}

.cpsTagProduct .content .productTitle .productName {
    font-family: Times New Roman, Times, serif;
    color: #333;
    margin-left: .2rem;
    font-size: .76rem;
    font-weight: 600
}

.cpsTagProduct .content .priceTip {
    min-height: .746667rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1.12rem
}

.cpsTagProduct .content .priceTip .priceLeft {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.cpsTagProduct .content .priceTip .company {
    width: .6rem;
    color: #fd5c59;
    text-align: center;
    font-size: .6rem;
    line-height: .6rem
}

.cpsTagProduct .content .priceTip .price {
    font-size: 1rem;
    color: #fd5c59;
    line-height: 1rem;
    margin-right: .24rem
}

.cpsTagProduct .content .priceTip .oldPrice {
    font-size: .439rem;
    color: #9494a6;
    text-decoration: line-through;
    line-height: .439rem
}

.cpsTagProduct .content .priceTip .tips {
    position: absolute;
    left: 3.36rem;
    bottom: .24rem;
    margin-left: .36rem;
    padding: 1px .2rem;
    border-radius: 2rem 2rem 2rem 0;
    color: #fff;
    background-color: #fd5c59
}

.cpsTagProduct .content .denomination {
    font-size: .5599rem;
    color: #333;
    padding-left: .4rem;
    font-weight: 600
}

.cpsTagProduct .content .denominationList {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.cpsTagProduct .content .denominationList .denominationItem {
    position: relative;
    width: 3.97rem;
    border: 1px solid transparent;
    text-align: center;
    background: #f8f8f7;
    border-radius: .279rem;
    margin-top: .3999rem;
    padding: .6rem 0;
    margin-left: .36rem
}

.cpsTagProduct .content .denominationList .denominationItem .name {
    font-size: .559rem;
    color: #333;
    font-weight: 500
}

.cpsTagProduct .content .denominationList .denominationItem .priceWarp {
    margin-top: .159rem;
    color: #fd5c59;
    font-weight: 600
}

.cpsTagProduct .content .denominationList .denominationItem .priceWarp > span:first-child {
    font-size: .48rem
}

.cpsTagProduct .content .denominationList .denominationItem .priceWarp > span:nth-child(2) {
    font-size: .6rem
}

.cpsTagProduct .content .denominationList .denominationItem .oldPrice {
    margin-top: .159rem;
    color: #9494a6;
    font-weight: 400;
    font-size: .439rem;
    text-decoration: line-through
}

.cpsTagProduct .content .denominationList .denominationItem .marker {
    position: absolute;
    top: -9px;
    height: .64rem;
    line-height: .64rem;
    padding: 0 .199rem;
    background: #fd5c59;
    border-radius: 50px 50px 50px 0;
    color: #fff
}

.cpsTagProduct .content .denominationList .denomination_active {
    background-color: #fff9f2;
    border: 1px solid #e6ba79
}

.cpsTagProduct .content .denominationList .denominationItem:nth-child(3n-2) {
    margin-left: 0
}

.cpsTagProduct .content .chargeAccount {
    color: #333;
    font-weight: 600;
    font-size: .6rem;
    margin-bottom: .2rem;
    margin-top: .6rem
}

.cpsTagProduct .content .chargeArea {
    color: #333;
    font-weight: 600;
    font-size: .6rem;
    margin-bottom: .2rem;
    margin-top: .225rem
}

.cpsTagProduct .content .form .area, .cpsTagProduct .content .form .inphone {
    border: 1px solid #f1f1f1;
    border-radius: .24rem;
    font-size: .6399rem
}

.cpsTagProduct .content .form .van-field__control::-webkit-input-placeholder {
    color: #999
}

.cpsTagProduct .content .form .van-field__control:-moz-placeholder, .cpsTagProduct .content .form .van-field__control::-moz-placeholder {
    color: #999
}

.cpsTagProduct .content .form .van-field__control::-ms-input-placeholder {
    color: #999
}

.cpsTagProduct .content .form .buttonWrap {
    margin-top: .39rem
}

.cpsTagProduct .content .form .buttonWrap .van-btnOn {
    height: 1.92rem;
    background: -webkit-gradient(linear, left top, right top, from(#f0d19e), to(#e6ba79));
    background: -o-linear-gradient(left, #f0d19e 0, #e6ba79 100%);
    background: linear-gradient(90deg, #f0d19e, #e6ba79);
    border-radius: .24rem;
    color: #68400b;
    font-size: .639rem;
    border: 1px solid transparent
}

.cpsTagProduct .introduce {
    border: 1px solid #f1f1f1;
    border-radius: .36rem;
    -webkit-box-shadow: 0 5px 8px 0 rgba(96, 83, 75, .05);
    box-shadow: 0 5px 8px 0 rgba(96, 83, 75, .05);
    margin: .24rem .6rem 1.8rem;
    background-color: #fff
}

.cpsTagProduct .footer {
    width: calc(100vw - 1.2rem);
    position: fixed;
    bottom: 0;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    padding: 0 .6rem;
    height: 2.7rem
}

.cpsTagProduct .footer, .cpsTagProduct .footer .order, .cpsTagProduct .footer .service {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.cpsTagProduct .footer .order, .cpsTagProduct .footer .service {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

.cpsTagProduct .footer .order > img, .cpsTagProduct .footer .service > img {
    width: .88rem;
    height: .88rem;
    margin-bottom: .12rem
}

.cpsTagProduct .footer .order > div, .cpsTagProduct .footer .service > div {
    font-size: .4rem;
    color: #333;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.cpsTagProduct .footer .order {
    background-color: #fff;
    min-height: auto
}

.cpsTagProduct .footer .tuikeBtn {
    width: 9.6rem;
    height: 1.76rem
}

.cpsTagProduct .footer .tuikeBtn, .cpsTagProduct .footer .tuikeBtn .extensionBtn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

.cpsTagProduct .footer .tuikeBtn .extensionBtn {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
    height: 100%;
    border-radius: 1rem 0 0 1rem;
    background: -webkit-gradient(linear, left top, right top, from(#4a4a4d), to(#3a3841));
    background: -o-linear-gradient(left, #4a4a4d, #3a3841);
    background: linear-gradient(90deg, #4a4a4d, #3a3841);
    vertical-align: middle
}

.cpsTagProduct .footer .tuikeBtn .extensionBtn > div {
    text-align: center;
    color: #f8f8f8;
    font-size: .48rem
}

.cpsTagProduct .footer .tuikeBtn .extensionBtn > div:first-child {
    font-size: .559rem
}

.cpsTagProduct .footer .tuikeBtn .purchase {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
    height: 100%;
    border-radius: 0 1rem 1rem 0;
    background: -webkit-gradient(linear, left top, right top, from(#31cbff), to(#02acf5));
    background: -o-linear-gradient(left, #31cbff, #02acf5);
    background: linear-gradient(90deg, #31cbff, #02acf5);
    vertical-align: middle
}

.cpsTagProduct .footer .tuikeBtn .purchase > div {
    font-size: .56rem;
    text-align: center;
    color: #f8f8f8
}

.cpsTagProduct .footer .tuikeBtn .purchase > div:nth-child(2) {
    font-size: .48rem
}

.cpsTagProduct .footer .footerBtnWarp {
    position: relative
}

.cpsTagProduct .footer .footerBtnWarp .tips {
    position: absolute;
    top: -10px;
    left: 40%;
    z-index: 1;
    background-color: #fd5c59;
    color: #fff;
    padding: 0 9px;
    border-radius: 20px;
    border: 1px solid #fff
}

.cpsTagProduct .footer .footerBtnWarp .tips:after {
    position: absolute;
    display: block;
    content: "";
    width: 6px;
    height: 4px;
    background: url(https://fulu-tuike.oss-cn-hangzhou.aliyuncs.com/picture/e1637136c99146679da80bdc41c36c23.png) no-repeat;
    background-size: 100% 100%
}

.cpsTagProduct .footer .btn {
    width: 9.519rem;
    height: 1.76rem;
    background: -webkit-gradient(linear, left top, right top, from(#31cbff), to(#02acf5));
    background: -o-linear-gradient(left, #31cbff, #02acf5);
    background: linear-gradient(90deg, #31cbff, #02acf5);
    border-radius: 1rem;
    line-height: 1.76rem;
    color: #fff;
    font-weight: 500;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.cpsTagProduct .footer .btn .van-button__text {
    font-size: .64rem
}


.cpsTagProduct .content .form .area, .cpsTagProduct .content .form .inphone {
    border: 1px solid #f1f1f1;
    border-radius: 0.24rem;
    font-size: .64rem;
}

.confirmAccount {
    height: 8.39rem;
    background-color: transparent;
    border-radius: .48rem
}

.confirmAccount .confirmWarp {
    width: 9.6rem;
    height: 6.84rem;
    text-align: center;
    border-radius: .48rem;
    background-color: #fff
}

.confirmAccount .confirmWarp .please {
    padding-top: .79rem;
    font-size: .64rem;
    color: #333;
    font-weight: 500
}

.confirmAccount .confirmWarp .pleaseAccount {
    font-size: .559rem;
    font-weight: 400;
    margin: .799rem 0 1.39rem
}

.confirmAccount .confirmWarp .pleaseAccount > span {
    color: #999
}

.confirmAccount .confirmWarp .confirmBtn {
    width: 4.32rem;
    height: 1.32rem;
    border-radius: .79rem;
    background: -webkit-gradient(linear, left top, right top, from(#31cbff), to(#02acf5));
    background: -o-linear-gradient(left, #31cbff, #02acf5);
    background: linear-gradient(90deg, #31cbff, #02acf5);
    color: #fff;
    font-size: .56rem;
    font-weight: 400;
    margin-left: 0
}

.confirmAccount .iconClose {
    position: absolute;
    left: 50%;
    bottom: 0;
    margin-left: -.459rem;
    font-size: .9199rem;
    color: #fff
}

</style>
